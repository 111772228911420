.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

table {
    border-spacing: 0;
    /*border: 5px solid #ededed;*/
    /*align-content: center;*/
    height: 500px;
    align-content: center;

}

#table1 {
    float: left;
    display: inline-block;
    width: 50%;
}

#table2 {
    float: right;
    display: inline-block;
    width: 50%;
    align-content: center;
}


#table3 {
    float: left;
    display: inline-block;
    width: 75%;
}

#table4 {
    float: left;
    display: inline-block;
    width: 25%;
    align-content: flex-start;
}
table tr:last-child td {
    border-bottom: 0;
}

table th,
table td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 2px solid #ededed;
    /*border-right: 2px solid #ededed;*/
    position: relative;
}

table th:last-child,
table td:last-child {
    border-right: 0;
}

/*table tr:nth-child(even) {*/
/*  background-color: #fafafa;*/
/*}*/

table thead tr:first-child {
    background-color: #77aaff;
}

table thead tr:nth-of-type(2) {
    background-color: #C3CCF1;
}

table th::before {
    position: absolute;
    right: 15px;
    top: 16px;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}


table th.sort-asc::before {
    border-bottom: 5px solid #22543d;
}

table th.sort-desc::before {
    border-top: 5px solid #22543d;
}

table tbody tr {
     border-bottom: 5px solid #22543d;
}

.App {
    display: flex;
    flex-direction: column;
    padding: 80px;
}

/* .row {
    margin-left: -5px;
    margin-right: -5px;
} */

.column {
    float: left;
    width: 50%;
    padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
    content: "";
    clear: both;
    display: table;
}

.badge {
    /*background-color: #9ae6b4;*/
    /*color: #22543d;*/
    margin-right: 4px;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
    /*text-transform: uppercase;*/
}

input {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: none;
}

.up-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid green;
    margin: 2rem;
    color: green;
    width: 5%
}
.down-arrow {
        margin: 2rem;
    color: red;
    width: 5%
}
