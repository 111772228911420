.logs-table-header {
  font-family: 'Amazon Ember Display', sans-serif;
  font-size: 1.5rem;
  background-color: #4779c4;
  color: #fff;
  font-weight: 600;
  text-align: center;
  border-right: 5px solid #fff;
}

.image {
  max-height: 700px;
}

.logs {
  font-family: 'Fira Code', monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  height: 100%;
  padding: 20px 5px;
}

.log-normal {
  display: block;
  color: #7E4FF3;
}

.log-error {
  display: block;
  color: #DB2BB6;
}

.log-success {
  display: block;
  color: #0D9F91;
}

.cursorBlink {
  font-weight: 100;
  font-size: 1rem;
  background-color: white;
  color: #fff;
  width: 0.5rem;
  min-width: 0.25rem;
  min-height: 1.5rem;
  overflow: hidden;
  animation: blink 1s steps(5, start) infinite;
}

@keyframes blink {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: #fff;
  }
}
@-moz-keyframes blink {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: #fff;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: #fff;
  }
}
@-ms-keyframes blink {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: #fff;
  }
}
@-o-keyframes blink {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: #fff;
  }
}
